import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from "moment";
import Logo from "../../assets/images/Varada_tech_logo_w.png";
import EmailIcon from "../../assets/images/email.png";
import SupportIcon from "../../assets/images/customer-service.png";
import LocationIcon from "../../assets/images/location.png";
import "./Footer.css";
import {
  SlSocialFacebook,
  SlSocialInstagram,
  SlSocialLinkedin,
  SlSocialYoutube,
} from "react-icons/sl";


function Footer() {
  return (
    <>
      <footer className="bg-blue text-white">
        <div className="px-2 py-lg-5 py-5">
          <Container fluid>
            <Row className="justify-content-center">
              <Col
                lg={9}
                sm={6}
                className="mb-lg-0 mb-0 ps-lg-3 d-lg-flex justify-content-between align-items-start"
              >
                <div className="d-flex gap-3 align-items-start justify-content-start mb-2">
                  <img src={EmailIcon} className="footer-icon" alt="" />
                  <div>
                    <p className="mb-0">Information and Complaint</p>
                    <h5 className="mb-0 fw-bold">
                      <a
                        href="mailto:info@varadatechsolutions.in"
                        className="text-white text-decoration-none"
                      >
                        info@varadatechsolutions.in
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="d-flex gap-3 align-items-start justify-content-start mb-2">
                  <img src={LocationIcon} className="footer-icon" alt="" />
                  <div>
                    <p className="mb-0">Visit Us</p>
                    <h5 className="mb-0 fw-bold">
                      <a
                        href="https://goo.gl/maps/tWudwm8A7jogBUzX6"
                        target="_blank"
                        className="text-white text-decoration-none"
                      >
                        HD-266, WeWork DLF Forum,
                        <br /> Cybercity, Phase-3, Gurugram,
                        <br />
                        Haryana-122002. INDIA
                      </a>
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-dark text-white py-lg-3 py-2">
          <Container fluid>
            <Row>
              <Col
                lg={6}
                className="text-lg-start text-center order-lg-1 order-2 small"
              >
                <div className="copyright">
                  Design{" "}
                  <a
                    href="http://globaltrendz.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-decoration-none"
                  >
                    Global Trendz
                  </a>
                </div>
              </Col>
              <Col
                lg={6}
                className="text-lg-end text-center order-lg-2 order-1 small"
              >
                <div className="copyright">
                  &copy; 2015-{moment().format("YYYY")} Varada Tech Solutions.
                  All right reserved.
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="top" style={{ cursor: "pointer", display: "none" }}>
          <i className="flaticon-chevron icontops"></i>
          <div className="bosluk3"></div>
        </div>
      </footer>
    </>
  );
}

export default Footer;