import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import CircleBgImage from "../../assets/images/rotate-2.png";
import AboutImage from "../../assets/images/about-1.png";
import CyberSecurity from "../../assets/images/cyber-security.png";
import TechnicalSupport from "../../assets/images/technical-support.png";
import './AboutSection.css';
import PostApiCall from '../../helper/PostAPI';
import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);
function AboutSection() {
  const [AboutSection, setAboutSection] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetAboutUs"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAboutSection(obj.data);
        }
      })
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="about-style-1 pt-lg-3 pt-3">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg={6} className="position-relative circle">
              <Image
                src={CircleBgImage}
                className="img-fluid w-75 aboutrotateslider"
                alt=""
              />
              <Image
                src={AboutImage}
                className="img-fluid w-100 about-img"
                alt=""
              />
            </Col>
            <Col lg={6} className="pt-lg-4 pt-4">
              {AboutSection.slice(0, 1).map((data, index) => {
                return (
                  <div key={index}>
                    <h2 className="main-title">{data.fld_title}</h2>
                    <p className="main-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data.fld_answer),
                        }}
                      />
                    </p>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default AboutSection;