import React from 'react'
import { Carousel } from 'react-bootstrap';
import SliderImageFirst from '../../assets/images/slider-images/1.jpg';
import SliderImageSecond from "../../assets/images/slider-images/2.jpg";
import "./MainSlider.css";
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function MainSlider() {
  return (
    <>
      <Carousel fade interval={5000}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={SliderImageFirst}
            alt="First slide"
          />
          <Carousel.Caption className="animate__animated animate__fadeIn animate__slow animate__delay-2s">
            <h1>
              We Are Born <br />
              From Technology
              <br />
              Getting Stronger{" "}
            </h1>
            <h4>The best technology.</h4>
            <Link
              to="/services"
              className="btn btn-primary btn-theme btn-lg mt-lg-4 text-capitalize d-flex justify-content-center align-items-center"
            >
              learn more <BsArrowRight className="ms-4" />
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={SliderImageSecond}
            alt="Second slide"
          />

          <Carousel.Caption className="animate__animated animate__fadeIn animate__slow animate__delay-2s">
            <h1>
              We Lead To New <br />
              Discoveries With <br /> New Generation{" "}
            </h1>
            <h4>More customers with digital marketing.</h4>
            <Link
              to="/services"
              className="btn btn-primary btn-theme btn-lg mt-lg-4 text-capitalize d-flex justify-content-center align-items-center"
            >
              learn more <BsArrowRight className="ms-4" />
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default MainSlider;