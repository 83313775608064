import React from 'react';
import AnimatedCursor from "react-animated-cursor";

function AnimatedCursorFun() {
  return (
    <AnimatedCursor
      innerSize={10}
      outerSize={40}
      color="#000000"
      outerAlpha={2}
      innerScale={2.5}
      outerScale={1}
      clickables={[
        "a",
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        "label[for]",
        "select",
        "textarea",
        "button",
        ".link",
      ]}
      outerStyle={{
        border: ".2px solid var(--color-dark)",
      }}
      innerStyle={{
        backgroundColor: "var(--color-dark)",
      }}
    />
  );
}

export default AnimatedCursorFun;