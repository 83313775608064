import React from "react";


function Productpage() {
    return (
        <>
        <h1>Productpage</h1>
        </>
    )
}

export default Productpage;