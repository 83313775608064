import React from 'react';
import './ParallaxSection.css';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ParallaxSection() {
  return (
    <>
      <section className="parallax height-35">
        <div className="text-center parallax-body">
          <h2 className="text-white my-lg-4 my-4">More than just a website.</h2>
          <Link to="/contact-us" className="btn btn-primary btn-theme btn-lg d-flex justify-content-center align-items-center mx-auto">
            Get Started <BsArrowRight className="ms-4" />
          </Link>
        </div>
        <div className="parallax-overlay"></div>
      </section>
    </>
  );
}

export default ParallaxSection;