import createDOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import PostApiCall from "../../helper/PostAPI";
import { Card, Col, Container, Image, Nav, Row, Tab } from "react-bootstrap";
import { CgEditBlackPoint } from "react-icons/cg";
import "./ServicesSection.css";
import PageBanner from "../../components/PageBanner/PageBanner";

const DOMPurify = createDOMPurify(window);
function ServicesAll() {
  const [Service, setService] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetServices"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setService(obj.data);
        }
      })
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section>
        <PageBanner />
        <Container className="py-lg-5 py-3" id="left-tabs-container">
          <Tab.Container
            id="left-tabs"
            defaultActiveKey="technology-development"
          >
            <Row>
              <Col sm={4}>
                <Card className="shadow border-0">
                  <Card.Body>
                    {/* <h4 className="shadow p-3 rounded">About</h4> */}
                    <Nav variant="pills" className="flex-column p-2">
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="technology-development"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" />{" "}
                          Technology Development
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="design-and-engineering"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" /> Design &
                          Engineering
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="procurement"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" />{" "}
                          Procurement
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="project-management"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" /> Project
                          Management
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={8} className="mt-lg-0 mt-3">
                <Tab.Content>
                  <Tab.Pane eventKey="technology-development">
                    {Service.slice(0, 1).map((data, index) => {
                      return (
                        <>
                          <Image
                            src={data.fld_img}
                            className="img-fluid rounded mb-3"
                          />
                          <h2 className="main-title text-dark">
                            {data.fld_heading}
                          </h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data.fld_description
                                ),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                  <Tab.Pane eventKey="design-and-engineering">
                    {Service.slice(1, 2).map((data, index) => {
                      return (
                        <>
                          <Image
                            src={data.fld_img}
                            className="img-fluid rounded mb-3"
                          />
                          <h2 className="main-title text-dark">
                            {data.fld_heading}
                          </h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data.fld_description
                                ),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                  <Tab.Pane eventKey="procurement">
                    {Service.slice(2, 3).map((data, index) => {
                      return (
                        <>
                          <Image
                            src={data.fld_img}
                            className="img-fluid rounded mb-3"
                          />
                          <h2 className="main-title text-dark">
                            {data.fld_heading}
                          </h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data.fld_description
                                ),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                  <Tab.Pane eventKey="project-management">
                    {Service.slice(3, 4).map((data, index) => {
                      return (
                        <>
                          <Image
                            src={data.fld_img}
                            className="img-fluid rounded mb-3"
                          />
                          <h2 className="main-title text-dark">
                            {data.fld_heading}
                          </h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data.fld_description
                                ),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
}

export default ServicesAll;
