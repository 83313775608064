import createDOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Nav, Row, Tab } from "react-bootstrap";
import { CgEditBlackPoint } from "react-icons/cg";
import PageBanner from "../components/PageBanner/PageBanner";
import PostApiCall from "../helper/PostAPI";
import { useLocation } from "react-router-dom";
const DOMPurify = createDOMPurify(window);

function Aboutpage() {
  let location1 = useLocation();
  const [About, setAbout] = useState([]);
  useEffect(() => {
    if (location1.state != undefined) {
      console.log(location1.state.Name)
    }
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetAboutUs"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setAbout(obj.data);
        }
      })
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <section>
        <PageBanner />
        <Container className="py-lg-5 py-3" id="left-tabs-container">
          <Tab.Container id="left-tabs" defaultActiveKey="overview">
            <Row>
              <Col sm={4}>
                <Card className="shadow border-0">
                  <Card.Body>
                    {/* <h4 className="shadow p-3 rounded">About</h4> */}
                    <Nav variant="pills" className="flex-column p-2">
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="overview"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" /> Overview
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="history-and-vision"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" /> History
                          &amp; Vision
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="me-0">
                        <Nav.Link
                          eventKey="expertise"
                          className="shadow p-3 rounded me-0 d-flex align-items-center gap-2"
                        >
                          <CgEditBlackPoint className="list-marker" /> Expertise
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={8} className="mt-lg-0 mt-3">
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    {About.slice(0, 1).map((data, index) => {
                      return (
                        <>
                          <h2 className="main-title">{data.fld_title}</h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(data.fld_answer),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                  <Tab.Pane eventKey="history-and-vision">
                    {About.slice(1, 2).map((data, index) => {
                      return (
                        <>
                          <h2 className="main-title">{data.fld_title}</h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(data.fld_answer),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                  <Tab.Pane eventKey="expertise">
                    {About.slice(2, 3).map((data, index) => {
                      return (
                        <>
                          <h2 className="main-title">{data.fld_title}</h2>
                          <p className="main-text">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(data.fld_answer),
                              }}
                            />
                          </p>
                        </>
                      );
                    })}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </>
  );
}

export default Aboutpage;
