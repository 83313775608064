import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./PageBanner.css";

function About(props) {
  let location = useLocation();
  return (
    <>
      <section className="page-banner">
        <div className="image-layer"></div>
        <div className="banner-inner">
          <div className="container">
            <div className="inner-container clearfix">
              <h1>
                {location.pathname == "/services-details"
                  ? props.service
                  : String(location.pathname)
                      .replace("/", "")
                      .replace("-", " ")
                      .replace("-", " ")}
              </h1>
              <div className="page-nav">
                <ul className="bread-crumb clearfix">
                  <li className="thm-breadcrumb__item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="thm-breadcrumb__item active">
                    <span>
                      {location.pathname == "/services-details"
                        ? props.service
                        : String(location.pathname)
                            .replace("/", "")
                            .replace("-", " ")
                            .replace("-", " ")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
