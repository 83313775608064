import createDOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import HeroImage from "../assets/images/hero-image.png";
import PostApiCall from "../helper/PostAPI";
import PageBanner from "../components/PageBanner/PageBanner";

const DOMPurify = createDOMPurify(window);
function Partners() {
  const [Partners, setPartners] = useState([]);
  const [PartnersList, setPartnersList] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPartners"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPartners(obj.data);
        }
      })
    );
  }, []);

  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetPartnersList"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setPartnersList(obj.data);
        }
      })
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section>
        <PageBanner />
        <Container>
          <Row className="pt-lg-5 pt-5 justify-content-center">
            <Col lg={10} className="text-center">
              {Partners.map((data, index) => {
                return (
                  <>
                    <p className="main-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data.fld_description),
                        }}
                      />
                    </p>
                  </>
                );
              })}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-style-1 pt-lg-5 pb-lg-3 py-4">
        <Container>
          <Row className="justify-content-center align-items-center">
            {PartnersList.filter((data) => {
              if (data.fld_status != "No") {
                return data
              }
            }).map((data, index) => {
              return (
                <Col lg={3} className="text-center mb-lg-0 mb-3">
                  <div className="p-4 border">
                    <h5>{data.fld_name}</h5>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Partners;
