import './App.css';
import Navbar from "../src/components/Navbar/Navbar";
import Footer from "../src/components/Footer/Footer";
import Home from '../src/pages/Homepage';
import About from '../src/pages/About';
import ContactUs from "../src/pages/ContactUs";
import Product from '../src/pages/Products';
import ServiceAll from "../src/components/ServicesSection/ServiceAll";
import Partners from "../src/pages/Partners";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AnimatedCursorFun from "./components/AnimatedCursor/AnimatedCursor";

function App() {
  return (
    <BrowserRouter>
      <AnimatedCursorFun />
      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/testimonials" element={<ContactUs />} />
        <Route exact path="/career" element={<ContactUs />} />
        <Route exact path="/blog" element={<ContactUs />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/services" element={<ServiceAll />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
