import React from "react";
import MainSlider from "../components/MainSlider/MainSlider";
import About from "../components/AboutSection/AboutSection";
import Services from "../components/ServicesSection/ServicesSection";
import Parallax from "../components/ParallaxSection/ParallaxSection"


function Homepage() {
    return (
      <>
        <MainSlider />
        <About />
        <Services />
        <Parallax />
      </>
    );
}

export default Homepage;