import React, { useEffect, useState } from "react";
import { Card, Offcanvas } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from "react-router-dom";
import './Navbar.css';
import Logo from "../../assets/images/Varada_tech_logo.png";
import { BsArrowRight } from "react-icons/bs";

function SiteNavbar() {
  const [scroll, setScroll] = useState(false);
  const [showAboutDropMenu, setShowAboutDropMenu] = useState(false);
  const [showServicesDropMenu, setshowServicesDropMenu] = useState(false);
  const [activeKey, setActiveKey] = useState("home");
  const [Offcanvas1, setOffcanvas1] = useState(false);
  const handleCloseoffcanvas = () => setOffcanvas1(false);
  const handleShowoffcanvas = () => setOffcanvas1(true);

  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        bg="white"
        expand="lg"
        className={scroll ? "sticky-top shadow-sm" : "sticky-top shadow-sm"}
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} alt="" className="logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle className="border-0" onClick={handleShowoffcanvas} />
          <Navbar.Offcanvas
            show={Offcanvas1}
            onHide={handleCloseoffcanvas}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="justify-content-end align-items-center">
              <Nav className="justify-content-center align-items-lg-center">
                <Link
                  to="/"
                  className="nav-link"
                  eventKey="0"
                  onClick={() => {
                    handleCloseoffcanvas();
                  }}
                >
                  Home
                </Link>
                <div onMouseLeave={() => setShowAboutDropMenu(false)}>
                  <Link
                    to="/about"
                    className="dropdown-toggle nav-link"
                    onMouseEnter={() => setShowAboutDropMenu(true)}
                  >
                    About
                  </Link>
                  {showAboutDropMenu && (
                    <Card className="dropdown-menu">
                      <Card.Body>
                        <Link
                          to={{
                            pathname: `/about`,
                            state: {
                              Name: `Overview`,
                            },
                          }}
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Overview
                        </Link>
                        <Link
                          to="/about"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          History & Vision
                        </Link>
                        <Link
                          to="/about"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Expertise
                        </Link>
                      </Card.Body>
                    </Card>
                  )}
                </div>
                <div onMouseLeave={() => setshowServicesDropMenu(false)}>
                  <Link
                    to="/services"
                    className="dropdown-toggle nav-link"
                    onMouseEnter={() => setshowServicesDropMenu(true)}
                  >
                    Services
                  </Link>
                  {showServicesDropMenu && (
                    <Card className="dropdown-menu">
                      <Card.Body>
                        <Link
                          to="/services"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Technology Development
                        </Link>
                        <Link
                          to="/services"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Design & Engineering
                        </Link>
                        <Link
                          to="/services"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Procurement
                        </Link>
                        <Link
                          to="/services"
                          className="nav-link"
                          eventKey="2"
                          onClick={() => {
                            handleCloseoffcanvas();
                          }}
                        >
                          Project Management
                        </Link>
                      </Card.Body>
                    </Card>
                  )}
                </div>
                <Link
                  to="/partners"
                  className="nav-link"
                  eventKey="3"
                  onClick={() => {
                    handleCloseoffcanvas();
                  }}
                >
                  Partners
                </Link>
                <Link
                  to="/contact-us"
                  className="nav-link"
                  eventKey="4"
                  onClick={() => {
                    handleCloseoffcanvas();
                  }}
                >
                  Contact Us
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default SiteNavbar;