import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import AboutImage from "../assets/images/image.png";
import HeroImage from "../assets/images/hero-image.png";
import EmailIcon from "../assets/images/email-contact.png";
import SupportIcon from "../assets/images/customer-service-contact.png";
import LocationIcon from "../assets/images/location-contact.png";
import PostApiCall from "../helper/PostAPI";
import createDOMPurify from "dompurify";
import PageBanner from "../components/PageBanner/PageBanner";
import { BsArrowRight } from "react-icons/bs";
import Notiflix from "notiflix";

const DOMPurify = createDOMPurify(window);
let EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function Contactpage() {
  const [ContactDetails, setContactDetails] = useState([]);
  const [name, setName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Contact, setContact] = useState("");
  const [Email, setEmail] = useState("");
  const [message, setmessage] = useState("");

  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "where fld_status = 'Yes'",
      },
      "GetContact"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setContactDetails(obj.data);
        }
      })
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const sendMail = () => {
    PostApiCall.postRequest(
      {
        id: null,
        name: name,
        company: CompanyName,
        contact: Contact,
        email: Email,
        message: message,
      },
      "SendEnquiryMailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("We've Got Your Message! Our Team Will Reach Out to You Shortly.")
          // history.push("/")
          window.location.reload();
        }
      })
    );
  }
  const sendForm = () => {
    if (name != "") {
      if (Email != "") {
        if (EmailRegex.test(Email)) {
          if (CompanyName != "") {
            if (Contact != "") {
              if (message != "") {
                sendMail();
              } else {
                Notiflix.Notify.failure("Please Enter Your Message");
              }
            } else {
              Notiflix.Notify.failure("please Enter Your Contact number");
            }
          } else {
            Notiflix.Notify.failure("please Enter Your Company Name");
          }
        } else {
          Notiflix.Notify.failure("please Enter Valid Email");
        }
      } else {
        Notiflix.Notify.failure("please Enter Your Email Address");
      }
    } else {
      Notiflix.Notify.failure("please Enter Your Name");
    }
  }
  return (
    <>
      <section>
        <PageBanner />
        <Container>
          <Row className="pt-lg-5 pt-5 pb-lg-5 justify-content-center">
            <Col lg={10} className="text-center">
              <p className="main-text">
                We'd love to hear from you! Contact us today for any inquiries,
                feedback, or collaborations. Our dedicated team is ready to
                assist you.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center">
            {ContactDetails.map((data, index) => {
              return (
                <Col lg={4} className="text-center mb-lg-0 mb-4" key={index}>
                  <Card className="shadow p-4 rounded-0">
                    <img
                      src={data.fld_icon}
                      className="img-fluid w-25 mx-auto mb-4"
                    />
                    <h5 className="text-uppercase fw-bold border-0">
                      {data.fld_title}
                    </h5>
                    <h5>
                      {data.fld_actionlink != null && data.fld_actionlink === "Corporate Office" ? (
                        <a
                          href={data.fld_actionlink}
                          className="text-dark text-decoration-none"
                          target="_blank"
                        >
                          {data.fld_answer}
                        </a>
                      ) : (
                        <a
                          href={data.fld_actionlink}
                          className="text-dark text-decoration-none"
                        >
                          {data.fld_answer}
                        </a>
                      )}
                    </h5>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row className="pt-lg-5 pt-5 pb-lg-5 pb-5 justify-content-start align-items-start">
            {/* <Col lg={6} className="mb-lg-0 mb-4">
              <Image src={AboutImage} className="img-fluid w-100" alt="" />
            </Col> */}
            <Col lg={12}>
              <h4 className="text-center">Write to us</h4>
              <p className="main-text text-center mb-4">
                Reach out to us and let us know how we can assist you.
              </p>
              <Row className="g-3 mb-3">
                <Col md>
                  <FloatingLabel controlId="floatingInputGrid" label="Name">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      className="rounded-0"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Email address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      className="rounded-0"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-3 mb-3">
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Company Name"
                  >
                    <Form.Control
                      type="text"
                      placeholder="company name"
                      className="rounded-0"
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="floatingInputGrid"
                    label="Phone Number"
                  >
                    <Form.Control
                      type="text"
                      placeholder="phone number"
                      className="rounded-0"
                      maxLength={10}
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="g-3 mb-3">
                <Col>
                  <FloatingLabel controlId="floatingInputGrid" label="Message">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Type your message here..."
                      className="rounded-0"
                      onChange={(e) => {
                        setmessage(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Button
                    type="submit"
                    className="btn-primary btn-theme d-flex justify-content-center align-items-center btn-lg mx-auto"
                    onClick={sendForm}
                  >
                    Send Form <BsArrowRight className="ms-4" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          className="p-0 d-flex justify-content-center align-items-center"
        >
          <iframe
            loading="lazy"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1753.2073308100655!2d77.09142959138804!3d28.497169498297996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b66c8c2f21%3A0xb8120a4412f7ea62!2sWeWork%20Forum!5e0!3m2!1sen!2sin!4v1683270621253!5m2!1sen!2sin"
            width="100%"
            height="450px"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </Container>
      </section>
    </>
  );
}

export default Contactpage;
