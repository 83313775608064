import React, { useEffect, useState } from "react";
import PostApiCall from "../../helper/PostAPI";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import CyberSecurity from "../../assets/images/cyber-security.png";
import ServiceOne from "../../assets/images/small-services-1.jpg";
import "./ServicesSection.css";
import { BsArrowRight } from "react-icons/bs";

function ServicesSection() {
  const [Service, setService] = useState([]);
  useEffect(() => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetServices"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setService(obj.data);
        }
      })
    );
  }, []);
  return (
    <>
      <section className="section-style-1 bg-blue">
        <Container fluid>
          <Row className="justify-content-between align-items-center">
            <Col lg={10} className="text-start">
              <h2 className="main-title text-white fw-bold">
                Our Technological IT Solutions For The Future Of Business
              </h2>
              <p className="main-text  text-white fw-bold wow animate__animated animate__fadeInUp">
                Let your workload lighten with our best digital solutions.
              </p>
              <p className="main-sub-text  text-white wow animate__animated animate__fadeInUp">
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.
              </p>
            </Col>
            <Col lg={2}>
              <a
                href="/services"
                className="btn btn-outline-light btn-theme text-capitalize btn-lg align-items-center"
              >
                all services <BsArrowRight className="ms-4" />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-style-2 pt-lg-5 pb-lg-3 py-4 margin-top-neg-175">
        <Container fluid>
          <Row className="">
            {Service.map((data, index) => {
              return (
                <Col lg={3} key={index} className="text-center mb-lg-0 mb-3">
                  <Card className="text-center rounded-0 border-0">
                    <Image src={data.fld_img} className="img-fluid w-100" />
                    <div className="descontent">
                      <img
                        src={CyberSecurity}
                        className="img-fluid w-25 mb-lg-2"
                      />
                      <h4 className="mb-lg-3 mb-3">{data.fld_heading}</h4>
                      <a
                        href="/services"
                        className="btn btn-outline-light btn-theme text-capitalize btn-lg"
                        onClick={() => {
                          localStorage.setItem(
                            "serviceId",
                            JSON.stringify(data.fld_id)
                          );
                        }}
                      >
                        {/* get service */}
                        Read More
                      </a>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ServicesSection;
